import { CodeBlock, dracula } from "react-code-blocks"

import { useState } from "react";
import { CodeAPI } from "../../scripts/API";

export const CodeTheatre = () => {
    const [ code, setCode ] = useState("");

    CodeAPI.getCode('theatre').then(response => {
        if(response.status === 200) {
            setCode(response.data);
        } else {
            setCode('// An error has occured, returned status : ' + response.status);
        }
    }).catch(error => {
        setCode('// An unkown error has occured');
    })

    return (
        <div style={{backgroundColor: `#282a36`, color: `white`}}>
            <h1 style={{textAlign: `center`}}>Code of 'Theatre' projets</h1>
            <CodeBlock
                text={code}
                language="typescript"
                showLineNumbers={true}
                theme={dracula}
            />
        </div>
    )
}