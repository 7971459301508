import { ReactNode, useEffect, useState } from 'react';
import styles from './styles/videoPlayer.module.css';
import { BrowserView, MobileView } from 'react-device-detect';

interface VideoPlayerProps {
    source: string;
    control?: boolean;
    className?: string;
    startOverlay?: ReactNode;
    endOverlay?: ReactNode;
    videoRef: React.MutableRefObject<HTMLVideoElement | null>;
}

export const VideoPlayer:React.FC<VideoPlayerProps> = ({source, control, className, startOverlay, endOverlay, videoRef}) => {
    const errorMessage:string = "Votre navigateur ne supporte pas la vidéo. Il est conseiller d'utiliser Google Chrome ou Fire Fox sur ordinateur.";
    
    const [ videoEnded, setVideoEnded ] = useState(false);
    const handleEndVideo = () => {
        setVideoEnded(true);
    }

    const [ canBePlayed, setCanBePlayed ] = useState(false);

    useEffect(() => {
        let currentVideoRef = videoRef.current;
        const handleVideoCanPlay = () => {
            setCanBePlayed(true);
        }

        if (currentVideoRef) {
            currentVideoRef.addEventListener('canplay', handleVideoCanPlay);
        }

        return () => {
            if (currentVideoRef) {
                currentVideoRef.removeEventListener('canplay', handleVideoCanPlay);
            }
        };
    }, [videoRef]);

    return (
        <div className={`${styles.container} ${className}`}>
            <BrowserView>
                <video ref={videoRef} controls={control} className={styles.video} onEnded={handleEndVideo}>
                    <source src={source} type="video/mp4" />
                </video>
                {
                    startOverlay && !videoEnded && canBePlayed ? (
                        <div className={styles.overlay}>
                            {startOverlay}
                        </div>
                    ) : <></>
                }
                {
                    endOverlay && videoEnded && canBePlayed ? (
                        <div className={styles.overlay}>
                            {endOverlay}
                        </div>
                    ) : <></>
                }
                <p style={canBePlayed ? {display: `none`} : {}}>{errorMessage}</p>
            </BrowserView>
            <MobileView><p>{errorMessage}</p></MobileView>
        </div>
    );
}