import { NotFound } from "../screens/404";
import { FoldersManager } from "../screens/Admin/FoldersManager";
import { CV } from "../screens/CV";
import { CodeTheatre } from "../screens/Code/Theatre";
import { Home } from "../screens/Home";
import { Projects } from "../screens/Projects";
import { Theatre } from "../screens/Projects/Theatre";

const Pages = [
    // Main pages
    {
        "path": "home",
        "element": <Home />
    },
    {
        "path": "cv",
        "element": <CV />
    },
    {
        "path": "projects",
        "element": <Projects />
    },

    // Projects
    {
        "path": "projects/theatre",
        "element": <Theatre />
    },

    // Code
    {
        "path": "code/theatre",
        "element": <CodeTheatre />
    },

    // Admin
    {
        "path": "admin/folders",
        "element": <FoldersManager />
    },

    // Utils
    {
        "path": "404",
        "element": <NotFound/>
    }
]

export default Pages;