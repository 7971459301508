import { useLocation } from "react-router-dom";
import Pages from "./config/Pages";
import { Folders } from "./screens/Folders";

function App() {
  const location = useLocation();
  const currentPath = location.pathname.slice(1) || "home";

  let currentPage;
  let path = "";
  if(currentPath.startsWith("f/")) {
    path = currentPath.slice(2);
  } else {
    currentPage = Pages.find(page => page.path === currentPath) || Pages.find(page => page.path === "404") || Pages[0];
  }

  return (
    <>
      {
        currentPage ? currentPage.element : <Folders path={path} />
      }
    </>
  );
}

export default App;
