import { useEffect, useState } from 'react';

import styles from './styles/folder.module.css';

import { AiFillFolder, AiOutlineLink } from 'react-icons/ai';

import { FoldersAPI } from '../scripts/API';

interface FoldersProps {
    path: string
}

export const Folders: React.FC<FoldersProps> = ({ path }) => {
    let foldersPath = path.split('/');

    if(foldersPath[foldersPath.length - 1] === "") {
        foldersPath = foldersPath.slice(0, foldersPath.length - 1);
    }

    const [notFound, setNotFound] = useState("");
    const [pathTraversed, setPathTraversed] = useState<any[]>([]);
    const [currentFolder, setCurrentFolder] = useState<any>([]);
    const [redirect, setRedirect] = useState({"link": "", "out": false});
    
    const go = (url:string, out?:boolean) => {
        if(!out) window.location.href = (out ? "https://" : "") + url;
        else setTimeout(() => {
            window.location.href = (out ? "https://" : "") + url;
        }, 1500)
    }
    
    useEffect(() => {
        FoldersAPI.getFolders(foldersPath.join('.')).then(response => {
            const apiFolders = response.data;
            
            let currentFolder:any = [];
            let pathTraversed:any = [];
            let notFound = "";
            let redirect = {"link": "", "out": false};
            
            const updateStates = () => {
                setNotFound(notFound);
                setPathTraversed(pathTraversed);
                setCurrentFolder(currentFolder);
                setRedirect(redirect);
                
                if(redirect.link !== "") go(redirect.link, redirect.out);
            }
            
            if(apiFolders.length === 0) {
                redirect = {"link": "/f/" + foldersPath.slice(0, foldersPath.length - 1).join('/') + "/?notFound=" + foldersPath[foldersPath.length - 1], "out": false};
                updateStates();
            } else {
                if(window.location.href.split('?').length === 2) {
                    notFound = window.location.href.split('?')[1].split('=')[1];
                }

                let traversed:any = [];
                for(let folder of foldersPath) {
                    pathTraversed.push({"display": apiFolders[(traversed.length !== 0 ? traversed.join('.') + "." : "") + folder], href: "/f" + traversed.join('/') + "/" + folder});
                    traversed.push(folder);
                }
                traversed = traversed.join(".");

                console.log(apiFolders)
                for(let testfolder of Object.keys(apiFolders)) {
                    if(testfolder.startsWith(traversed + ".")) {
                        console.log(testfolder);
                        console.log(apiFolders[testfolder])
                        console.log(typeof(apiFolders[testfolder]))
                        if(typeof(apiFolders[testfolder]) === "string") {
                            currentFolder.push({
                                "display": apiFolders[testfolder],
                                "id": testfolder
                            })
                        } else {
                            currentFolder.push({
                                "display": apiFolders[testfolder].name,
                                "id": testfolder,
                                "link": apiFolders[testfolder].link
                            })
                        }
                    }
                }

                updateStates();
            }
        })
    }, [])

    return (
        <>
            {
                redirect.link !== "" ? (
                    <>
                        {
                            <div className={styles.loaderContainer}>
                                <div className={styles.loader}></div>
                                <h1>Redirecting...</h1>
                            </div>
                        }
                    </>
                ) : (
                    <>
                        {
                        <div className={styles.page}>
                            <h2>{notFound ? "Elément introuvable : " + notFound : ""} </h2>
                            <h1>Elements disponibles :</h1>
                            <div className={styles.container}>
                                <div className={styles.header}>
                                    <AiFillFolder color='#f8d875'/>
                                    <div className={styles.pathContainer}>
                                        {
                                            pathTraversed.map((folder, k) => (
                                                <p key={"path-separator-" + k}><a href={folder.href} key={"path-part-" + k}>{ folder.display }</a> /</p>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className={styles.body}>
                                    {
                                        currentFolder.map((element:any, k:any) => (
                                            <li className={styles.element} key={"element-" + k}>
                                                {element.link ? <AiOutlineLink /> : <AiFillFolder color='#f8d875'/>}<a href={element.link ? element.link : ("/f/" + element.id.split('.').join('/'))}>{element.display}</a>
                                            </li>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        }
                    </>
                )
            }
        </>
    )
}