import { ReactNode } from "react";

import styles from './styles/citation.module.css';

interface CitationProps {
    children: ReactNode;
}

export const Citation:React.FC<CitationProps> = ({children}) => {
    return (
        <p className={styles.citation}>{children}</p>
    )
}