import axios from "axios"
import { APIURL, STATICURL } from '../config/Constants';

export class FoldersAPI {
    static async getFolders(path?:string) {
        console.log("getfolder", path)
        return sendRequest(APIURL + "/folders/list.php" + (path ? "?path=" + path : ""));
    }
    static async getAllFolders(path?:string) {
        return sendRequest(APIURL + "/folders/list.php?all=true");
    }
    static async createFolder(path:string, name:string) {
        return sendRequest(APIURL + `/folders/folders.php?action=add&name=${name}&path=${path}`);
    }
    static async createLink(path:string, name:string, link:string) {
        return sendRequest(APIURL + `/folders/link.php?action=add&name=${name}&path=${path}&link=${link}`);
    }
    static async deleteLink(path:string) {
        return sendRequest(APIURL + `/folders/link.php?action=remove&path=${path}`);
    }
    static async deleteFolder(path:string) {
        return sendRequest(APIURL + `/folders/folders.php?action=remove&path=${path}`);
    }
}

export class CodeAPI {
    static async getCode(name:string) {
        return sendRequest(STATICURL + `/code/${name}.txt`);
    }
}


async function sendRequest(url: string) {
    return axios.get(url);
}