import { Controls, Player } from "@lottiefiles/react-lottie-player"
import { Navbar } from "../modules/Navbar"

import styles from './styles/home.module.css';
import Countdown from "react-countdown";

export const Home = () => {
    const countdownRenderer = ({ days, hours, minutes, seconds, completed}: {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
        completed: boolean;
    }) => {
        if(completed) {
            return (<span>Welcome !</span>);
        } else {
            return <h1><span>{days}</span>d <span>{hours}</span>h <span>{minutes}</span>m <span>{seconds}</span>s</h1>
        }
    }

    return (
        <>
            <Navbar />
            <div className={styles.container}>
                <div className={styles.animation}>
                    <Player
                        autoplay
                        loop
                        src={"https://assets-v2.lottiefiles.com/a/9fe12c24-1178-11ee-be5f-830c8b48a4db/kOHs0bIX8p.json"}
                        className={styles.player}
                    >
                        <Controls visible={false} />
                    </Player>
                </div>
                <div className={styles.text}>
                    <h1>Welcome</h1>
                    <h2>This webiste is under construction</h2>
                    <div className={styles.counter}>
                        <Countdown
                            date={1697839200000}
                            renderer={countdownRenderer}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}