import { useEffect, useState } from "react";
import { Navbar } from "../modules/Navbar"

import styles from './styles/cv.module.css';

export const CV = () => {
    const [scrollPercentage, setScrollPercentage] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const scrolled = Math.round((scrollTop / (scrollHeight - windowHeight)) * 100);

            setScrollPercentage(scrolled);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const scrollTo = (page: string) => {
        const offsetTop = document.getElementById(page)?.offsetTop || 0;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const marginTop = windowHeight / 4;
        const top = offsetTop - marginTop;
        window.scrollTo({ top: top, left: 0, behavior: 'smooth' });
    }

    return (
        <>
            <Navbar />
            <nav className={styles.navbar}>
                <div className={styles.mainBar}><div className={styles.activeBar} style={{ height: `${scrollPercentage}%` }}></div></div>
                <div className={styles.pageContainer}>
                    <div className={`${styles.page} ${styles.active}`} onClick={() => scrollTo('presentation')}>
                        <span className={styles.pageNumber}>1</span>
                        <p className={styles.pageName}>Présentation</p>
                    </div>
                    <div className={`${styles.page} ${scrollPercentage >= 30 ? styles.active : ""}`} onClick={() => scrollTo('trainings')}>
                        <span className={styles.pageNumber}>2</span>
                        <p className={styles.pageName}>Formations</p>
                    </div>
                    <div className={`${styles.page} ${scrollPercentage >= 53 ? styles.active : ""}`} onClick={() => scrollTo('experiences')}>
                        <span className={styles.pageNumber}>3</span>
                        <p className={styles.pageName}>Expériences</p>
                    </div>
                    <div className={`${styles.page} ${scrollPercentage >= 77 ? styles.active : ""}`} onClick={() => scrollTo('skills')}>
                        <span className={styles.pageNumber}>4</span>
                        <p className={styles.pageName}>Compétences</p>
                    </div>
                    <div className={`${styles.page} ${scrollPercentage === 100 ? styles.active : ""}`} onClick={() => scrollTo('contacts')}>
                        <span className={styles.pageNumber}>5</span>
                        <p className={styles.pageName}>Contacts</p>
                    </div>
                </div>
            </nav>
            <div className="container">
                <section className={styles.header}>
                    <h1>My CV</h1>
                </section>
                <section className={styles.part} id="presentation">
                    <div className={styles.title}>
                        <div className={styles.iconcontainer}></div>
                        <h1>Présentations</h1>
                    </div>
                </section>
                <section className={styles.part} id="trainings">
                    <div className={styles.title}>
                        <div className={styles.iconcontainer}></div>
                        <h1>Formations</h1>
                    </div>
                </section>
                <section className={styles.part} id="experiences">
                    <div className={styles.title}>
                        <div className={styles.iconcontainer}></div>
                        <h1>Experiences</h1>
                    </div>
                </section>
                <section className={styles.part} id="skills">
                    <div className={styles.title}>
                        <div className={styles.iconcontainer}></div>
                        <h1>Compétences</h1>
                    </div>
                </section>
                <section className={styles.part} id="contacts">
                    <div className={styles.title}>
                        <div className={styles.iconcontainer}></div>
                        <h1>Contacts</h1>
                    </div>
                </section>
            </div>
        </>
    )
}