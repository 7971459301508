import { MouseEventHandler, useRef, useState } from "react";
import styles from "../styles/theatre.module.css";

import { BsFillBuildingFill, BsFillPersonFill } from "react-icons/bs";
import { FaPeopleGroup } from "react-icons/fa6";
import { VideoPlayer } from "../../components/VideoPlayer";
import { Citation } from "../../components/Citation";
import { ReferencedImage } from "../../components/ReferencedImage";

interface Pieces {
  [key: string]: {
    displayName: string;
    headerBackground: string;
    headerBackgroundFit: string;
    headerBackgroundColor: string;
    menuColor: string;
  };
}

export const Theatre = () => {
  const homeVideoRef = useRef<HTMLVideoElement | null>(null);

  const pieces: Pieces = {
    home: {
      displayName: "Accueil",
      headerBackground: "https://static.maximedr.be/images/vilar_bat.jpg",
      headerBackgroundFit: "cover",
      headerBackgroundColor: "black",
      menuColor: "black",
    },
    impressario: {
      displayName: "L'impressario de Smyrne",
      headerBackground:
        "https://static.maximedr.be/images/impressario_smyrne_header.jpg",
      headerBackgroundFit: "contain",
      headerBackgroundColor: "black",
      menuColor: "white",
    },
    garcon: {
      displayName: "Le garçon du dernier rang",
      headerBackground: "https://static.maximedr.be/images/garcon_header.jpg",
      headerBackgroundFit: "contain",
      headerBackgroundColor: "black",
      menuColor: "white",
    },
    bibliographie: {
      displayName: "Bibliographie",
      headerBackground: "https://static.maximedr.be/images/bibliographie.jpg",
      headerBackgroundFit: "cover",
      headerBackgroundColor: "black",
      menuColor: "white",
    },
  };
  const [piece, setPiece] = useState("home");
  const handleSetPiece = (name: string): MouseEventHandler<HTMLDivElement> => {
    return (event) => {
      setPiece(name);
      setHeaderExtend(false);
    };
  };

  const [headerExtend, setHeaderExtend] = useState(false);
  const handleHeaderExtend = () => {
    setHeaderExtend(!headerExtend);
  };

  const [homeVideoStarted, setHomeVideoStarted] = useState(false);

  const startHomeVideo = () => {
    if (homeVideoRef.current) {
      homeVideoRef.current.play();
      setHomeVideoStarted(true);
    } else {
      console.log("video ref is null");
    }
  };

  const goToImpressario = () => {
    setPiece("impressario");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const goToGarcon = () => {
    setPiece("garcon");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className={styles.pageContainer}>
      <header className={`${headerExtend ? styles.headerExtended : ""}`}>
        <div className={styles.headerMenu}>
          <div className={styles.burger} onClick={handleHeaderExtend}>
            <div
              style={{ backgroundColor: `${pieces[piece].menuColor}` }}
            ></div>
            <div
              style={{ backgroundColor: `${pieces[piece].menuColor}` }}
            ></div>
            <div
              style={{ backgroundColor: `${pieces[piece].menuColor}` }}
            ></div>
          </div>
          <h3
            className={styles.name}
            style={{ color: `${pieces[piece].menuColor}` }}
          >
            Maxime Drooghaag
          </h3>
        </div>
        <div
          className={`${styles.headerNav} ${
            !headerExtend ? styles.hidden : ""
          }`}
        >
          <div className={styles.navhome} onClick={handleSetPiece("home")}>
            <h1>Accueil</h1>
          </div>
          <div
            className={styles.navimpressario}
            onClick={handleSetPiece("impressario")}
          >
            <h1>L'Impressario de Smyrne</h1>
          </div>
          <div className={styles.navgarcon} onClick={handleSetPiece("garcon")}>
            <h1>Le Garçon du dernier rang</h1>
          </div>
          <div className={styles.navlucrèce}>
            <h1>Lucrèce Borgia</h1>
          </div>
        </div>
      </header>
      <div
        className={styles.titleContainer}
        style={{
          backgroundImage: `url(${pieces[piece].headerBackground})`,
          backgroundSize: `${pieces[piece].headerBackgroundFit}`,
          backgroundColor: `${pieces[piece].headerBackgroundColor}`,
        }}
      >
        <h1 className={`${styles.title} ${headerExtend ? styles.hidden : ""}`}>
          {pieces[piece].displayName}
        </h1>
      </div>
      <section className={piece !== "home" ? styles.hide : ""}>
        <VideoPlayer
          source="https://static.maximedr.be/videos/theatre_intro.mp4"
          videoRef={homeVideoRef}
          startOverlay={
            <div
              className={`${styles.homeVideoControls} ${
                homeVideoStarted ? styles.hidden : ""
              }`}
            >
              <h1>Bienvenue</h1>
              <h2>Au théatre</h2>
              <p
                className={styles.link}
                onClick={startHomeVideo}
                style={{ fontSize: `20px` }}
              >
                Entrer
              </p>
            </div>
          }
          endOverlay={
            <div className={`${styles.homeSelection}`}>
              <p
                className={`${styles.link} ${styles.videoLink}`}
                onClick={goToImpressario}
                style={{ left: `20%`, top: `46%` }}
              >
                Carlo Goldoni
                <br />
                L'impressario de Smyrne
              </p>
              <p
                className={`${styles.link} ${styles.videoLink}`}
                onClick={goToGarcon}
                style={{ left: `55%`, top: `50%` }}
              >
                Juan Mayorga
                <br />
                Le Garçon du dernier rang
              </p>
              <p
                className={`${styles.link} ${styles.videoLink}`}
                style={{ left: `86%`, top: `46%` }}
              >
                Bientot...
                <br />
                Lucrère Borgia
              </p>
            </div>
          }
        />
      </section>
      <section className={piece !== "garcon" ? styles.hide : ""}>
        <div className={styles.part}>
          <div className={styles.header}>
            <h1>
              Qu'est ce que <i>Le garçon du dernier rang</i> ?
            </h1>
          </div>
          <div className={styles.body}>
            <ul>
              <li>
                <span className={styles.propertyName}>
                  <BsFillPersonFill /> Auteur :{" "}
                </span>
                Juan Mayorga
              </li>
              <li>
                <span className={styles.propertyName}>
                  <BsFillPersonFill /> Metteur en scène :{" "}
                </span>
                Jessica Gazon
              </li>
              <li>
                <span className={styles.propertyName}>
                  <FaPeopleGroup /> Acteurs principaux :{" "}
                </span>
                Astrid De Toffol, Monia Douieb, Hyacinthe Hennae, Louise
                Manteau, Morena Prats et Anna Solomin-Ohanian
              </li>
              <li>
                <span className={styles.propertyName}>
                  <BsFillBuildingFill /> Représentation :{" "}
                </span>
                Le 27 mars 2024 au Studio 12
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.header}>
            <h1>De quoi ça parle ?</h1>
          </div>
          <div className={styles.body}>
            <div className={`${styles.split} ${styles.horizontal}`}>
              <div className={styles.splitpart}>
                Une professeure de littérature corrige les rédactions de ses
                élèves. Parmi une multitude de copies médiocres, surgit une
                pépite. C’est le texte de Claude, ce garçon toujours installé au
                fond de la classe. Son sujet, la vie d’un de ses camarades de
                classe. Fascinée par l’écriture du jeune homme qui flirte entre
                voyeurisme et exercice littéraire, l’enseignante l’encourage à
                poursuivre ce feuilleton…
                <br />
                <Citation>
                  "Le Garçon du dernier rang - Le Vilar", Le Vilar [en ligne],
                  2024, sur{" "}
                  <a href="https://levilar.be/la-saison/le-garcon-du-dernier-rang/">
                    https://levilar.be/la-saison/le-garcon-du-dernier-rang/
                  </a>{" "}
                  , page consultée le 28 mars 2024
                </Citation>
              </div>
              <div style={{ width: `400px` }}>
                <ReferencedImage
                  className={styles.impressarioSummaryImage}
                  src="https://static.maximedr.be/images/garcon_illustration.jpg"
                  reference={
                    <p>
                      Le garçon du dernier rang, sur{" "}
                      <a href="https://levilar.be/wp-content/uploads/2023/03/web9-1024x719.jpg">
                        https://levilar.be/wp-content/uploads/2023/03/web9-1024x719.jpg
                      </a>{" "}
                      copié le 28/03/2024
                    </p>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.header}>
            <h1>Positif ou négatif ?</h1>
          </div>
          <div className={styles.body}>
            <p>
              Cette représentation de la pièce m'a beaucoup marquée.
              <br />
              <br />
              L'une des raisons est que cette pièce a su me toucher grâce à la
              facilité avec laquelle j'ai pu m'identifier aux personnages. En
              effet, il s'agit d'étudiants qui ont à peu près notre âge, qui
              vont à l’école, qui font du sport (le cas échéant : du basket),
              etc. Cela m'a permis de ressentir une proximité grâce à laquelle
              j'ai pu me plonger plus aisément dans l'histoire et vivre leurs
              émotions et leurs expériences de manière beaucoup plus intense.
              <br />
              <br />
              Un autre aspect remarquable de la représentation concernait son
              accompagnement musical et lumineux. Les créateurs ont fait preuve
              d'une grande ingéniosité en utilisant ces éléments pour mettre en
              valeur les moments forts de l'histoire, ce qui a contribué à
              renforcer le ressenti émotionnel des scènes clés.
              <br />
              <br />
              Enfin, l'agencement de la scène a permis de comprendre la mise en
              abyme de la pièce sans difficultés. La scène était divisée en
              plusieurs parties, dont certaines étaient mobiles pour que les
              acteurs puissent se déplacer entre les lieux sans problème.
              Ajoutez à cela les modifications d’éclairages entre les lieux et
              certains éléments qui tombent du plafond ou qui se lève du sol
              pour des effets spéciaux qui vous laisseront sans voix.
            </p>
            <br />
            <h2>Note : 4.5/5</h2>
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.header}>
            <h1>
              Description de l'espace scénographique et analyse de sa dimension
              symbolique
            </h1>
          </div>
          <div className={styles.body}>
            <p>
              Dans la pièce "Le garçon du dernier rang" mise en scène par
              Jessica Gazon, la scène est "séparée" en trois grandes parties.
              <br />
              <br />
              À gauche se trouve la maison de Madame Germain et sa compagne,
              représentée par un canapé posé sur un sol recouvert de feuilles.
              La plupart d'entre elles sont collées au sol, mais certaines sont
              utilisées dans la pièce par Madame Germain, et d'autres peuvent
              même tomber du plafond. Vers la fin de la pièce, nous découvrirons
              que ce sol peut être redressé.
              <br />
              <br />
              À l'arrière se trouvent des tableaux transparents dans un endroit
              réservé aux discussions entre Madame Germain et Claude. Au fur et
              à mesure que la pièce avance, Claude les complètera avec des
              dessins ou de l'écriture, ce qui donne une dimension symbolique à
              l'œuvre. Ces tableaux représentent des vues de la résidence de son
              ami Raphaël, chacun étant doté d'un titre évoquant différents
              aspects psychologiques de l'œuvre.
              <br />
              <br />
              À droite, il y a la maison de Rapha et sa famille, représentée par
              une maison en bois sur roulette. Les murs et le toit de la maison
              sont composés uniquement de latte de bois. Seul un drap vient à un
              moment de la pièce recouvrir ces lattes. Pour avoir plus de place,
              la maison pour s’ouvrir en deux parties. Ce montage ouvert offre
              une transparence totale sur la vie de ses occupants. Cela met en
              avant le voyeurisme de Claude, omniprésent tout au long de la
              pièce.
              <br />
              <br />
              Cette mise en scène permet de n’avoir aucune frontière physique
              entre les lieux. Cette absence permet à Claude d’évoluer d'un
              décor à l'autre lorsqu’il récite ses écrits, passant
              insensiblement de la présence de Madame Germain à celle de la
              famille de Rapha. Cette fluidité illustre l'absence de frontière
              entre les créations du jeune protagoniste et le quotidien de la
              famille de son camarade de classe.
              <br />
              <br />
              Enfin, dans les dernières répliques de la pièce, les lieux se
              confondent, car ils sont tous reliés par l'intermédiaire de Claude
              qui s'introduit progressivement dans chacun d'entre eux. Ce
              procédé vient encore une fois souligner le voyeurisme du
              personnage.
            </p>
          </div>
        </div>
      </section>
      <section className={piece !== "impressario" ? styles.hide : ""}>
        <div className={styles.part}>
          <div className={styles.header}>
            <h1>
              Qu'est ce que <i>L'impressario de Smyrne</i> ?
            </h1>
          </div>
          <div className={styles.body}>
            <ul>
              <li>
                <span className={styles.propertyName}>
                  <BsFillPersonFill /> Auteur :{" "}
                </span>
                Carlo Goldoni
              </li>
              <li>
                <span className={styles.propertyName}>
                  <BsFillPersonFill /> Metteur en scène :{" "}
                </span>
                Laurent Pelly
              </li>
              <li>
                <span className={styles.propertyName}>
                  <FaPeopleGroup /> Acteurs principaux :{" "}
                </span>
                Raphaël Bremard, Cyril Collet, Thomas Condemine, Natalie Dessay,
                Eddy Letexier, Antoine Minne, Julie Mossay, Jeanne Piponnier et
                l'ensemble baroque Masques
              </li>
              <li>
                <span className={styles.propertyName}>
                  <BsFillBuildingFill /> Représentation :{" "}
                </span>
                Le 12 septembre 2023 à l'Aula Magna
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.header}>
            <h1>De quoi ça parle ?</h1>
          </div>
          <div className={styles.body}>
            <div className={`${styles.split} ${styles.horizontal}`}>
              <div className={styles.splitpart}>
                Dans la flamboyante Venise du XVIIIème siècle, les esprits
                s’agitent et les égos se gonflent autour d’un nabab turc qui
                s’improvise « producteur » pour créer à Smyrne, sa ville natale,
                le plus fabuleux opéra de tous les temps. Il souhaite engager
                les plus grands artistes. De la diva jusqu’au librettiste, tout
                le cercle du spectacle est en effervescence.
                <br />
                <br />
                Goldoni peint avec férocité et tendresse, humour et cruauté, le
                monde des artistes et leurs travers très humains dans une
                comédie sur l’aveuglante envie de réussir jusqu’à l’excès.
                Autour des intrigues qui se nouent et se dénouent, le maitre
                italien nous entraîne dans un pétaradant tourbillon de joie, de
                disputes et d’inventions au charme irrésistible.
                <br />
                <Citation>
                  "L'Impresario de Smyrne - Le Vilar", Le Vilar [en ligne],
                  2023, sur{" "}
                  <a href="https://levilar.be/la-saison/smyrne/">
                    https://levilar.be/la-saison/smyrne/
                  </a>{" "}
                  , page consultée le 16 septembre 2023
                </Citation>
              </div>
              <div style={{ width: `400px` }}>
                <ReferencedImage
                  className={styles.impressarioSummaryImage}
                  src="https://static.maximedr.be/images/impressario_smyrne_illustration.jpg"
                  reference={
                    <p>
                      L'impressario de Smyrne, sur{" "}
                      <a href="https://levilar.be/wp-content/uploads/2023/03/Smyrne01-%C2%A9D.-Breda.jpg">
                        https://levilar.be/wp-content/uploads/2023/03/Smyrne01-%C2%A9D.-Breda.jpg
                      </a>{" "}
                      copié le 16/09/2023
                    </p>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.part}>
          <div className={styles.header}>
            <h1>Positif ou négatif ?</h1>
          </div>
          <div className={styles.body}>
            La représentation de l’Impresario de Smyrne a laissé en moi un
            sentiment très partagé. <br />
            <br />
            Dès notre entrée dans la salle, l’impressionnant cadre penché sur
            scène, ainsi que le fait que la scène elle-même soit penchée en
            direction du public, nous font questionner. Cela nous donne envie de
            voir la pièce pour comprendre, avant même que celle-ci n’ait
            commencé. <br />
            <br />
            Au début de la représentation, la technique est impressionnante. Le
            talent des acteurs et des musiciens nous prend de haut, nous
            plongeant instantanément dans l’ambiance de la pièce. L’immersion
            est garantie d’autant que le quatrième mur est brisé, les acteurs
            nous parle de face. Cela nous donne un sentiment d’importance dans
            la pièce. De temps en temps, les acteurs nous font des apartés, qui
            nous permettent d’avoir des informations que les autres personnages
            n’ont pas. Tout cela contribue à nous maintenir attentif pendant
            toute la pièce. <br />
            <br />
            Néanmoins, après quelque temps, la pièce s’éternise et tourne en
            rond, la rendant très longue. Cette longueur nous fait perdre le fil
            rouge de l’histoire, qui est compliqué à retrouver une fois perdu.
            Vers la fin, c’est à se demander si ça vaut encore la peine de
            rester. Le décor, presque fixe, n’aide pas dans cette longueur.
            C’est parfois assez compliqué de savoir dans quel espace-temps sont
            les acteurs. Malgré la présence de certaines phrases comiques, dans
            un langage plus familier, et d’actions surprenantes, car
            inattendues, cela ne compense pas la longueur de la pièce. Vraiment
            dommage pour une pièce et des acteurs qui ont du potentiel !<br />
            <br />
            <h2>Note : 3/5</h2>
          </div>
        </div>
      </section>
      <section className={piece !== "bibliographie" ? styles.hide : ""}>
        <div className={styles.biblio}>
          <h1>Menu de navigation</h1>
          <ul>
            <li>
              <span className={styles.propertyName}>
                Photo "Atelier Théatre Jean Vilar" :{" "}
              </span>
              sur{" "}
              <a href="https://rueslln.org/images/stories/vilar_pr.jpg">
                https://rueslln.org/images/stories/vilar_pr.jpg
              </a>{" "}
              copié le 16/09/2023
            </li>
            <li>
              <span className={styles.propertyName}>
                Portrait de Carlo Goldoni :{" "}
              </span>
              sur{" "}
              <a href="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Alessandro_Longhi_-_Ritratto_di_Carlo_Goldoni_%28c_1757%29_Ca_Goldoni_Venezia_-_Close-up.jpg/220px-Alessandro_Longhi_-_Ritratto_di_Carlo_Goldoni_%28c_1757%29_Ca_Goldoni_Venezia_-_Close-up.jpg">
                https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Alessandro_Longhi_-_Ritratto_di_Carlo_Goldoni_%28c_1757%29_Ca_Goldoni_Venezia_-_Close-up.jpg/220px-Alessandro_Longhi_-_Ritratto_di_Carlo_Goldoni_%28c_1757%29_Ca_Goldoni_Venezia_-_Close-up.jpg
              </a>{" "}
              copié le 16/09/2023
            </li>
          </ul>
          <h1>Page d'accueil</h1>
          <ul>
            <li>
              <span className={styles.propertyName}>Photo du Vilar : </span>sur{" "}
              <a href="https://ds.static.rtbf.be/article/image/1248x702/a/3/f/33ebd5b07dc7e407752fe773eed20635-1554903039.jpg">
                https://ds.static.rtbf.be/article/image/1248x702/a/3/f/33ebd5b07dc7e407752fe773eed20635-1554903039.jpg
              </a>{" "}
              copié le 16/09/2023
            </li>
            <li>
              <span className={styles.propertyName}>
                Portrait de Carlo Goldoni :{" "}
              </span>
              sur{" "}
              <a href="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Alessandro_Longhi_-_Ritratto_di_Carlo_Goldoni_%28c_1757%29_Ca_Goldoni_Venezia_-_Close-up.jpg/220px-Alessandro_Longhi_-_Ritratto_di_Carlo_Goldoni_%28c_1757%29_Ca_Goldoni_Venezia_-_Close-up.jpg">
                https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Alessandro_Longhi_-_Ritratto_di_Carlo_Goldoni_%28c_1757%29_Ca_Goldoni_Venezia_-_Close-up.jpg/220px-Alessandro_Longhi_-_Ritratto_di_Carlo_Goldoni_%28c_1757%29_Ca_Goldoni_Venezia_-_Close-up.jpg
              </a>{" "}
              copié le 16/09/2023
            </li>
            <li>
              <span className={styles.propertyName}>
                Portrait de Juan Mayorga :{" "}
              </span>
              sur{" "}
              <a href="https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Mayorga%2C_Juan_-NF_20181130_fRF06.jpg/220px-Mayorga%2C_Juan_-NF_20181130_fRF06.jpg">
                https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Mayorga%2C_Juan_-NF_20181130_fRF06.jpg/220px-Mayorga%2C_Juan_-NF_20181130_fRF06.jpg
              </a>{" "}
              copié le 16/09/2023
            </li>
            <li>
              <span className={styles.propertyName}>
                Portrait de Victor Hugo :{" "}
              </span>
              sur{" "}
              <a href="https://www.francepodcasts.com/wp-content/uploads/2023/05/Victor-Hugo.jpg">
                https://www.francepodcasts.com/wp-content/uploads/2023/05/Victor-Hugo.jpg
              </a>{" "}
              copié le 16/09/2023
            </li>
            <li>
              <span className={styles.propertyName}>
                Modèles 3D et textures :{" "}
              </span>
              sur{" "}
              <a href="https://www.blenderkit.com/">
                https://www.blenderkit.com/
              </a>{" "}
              (version gratuite) copié le 14/09/2023
            </li>
          </ul>
          <h1>Page "L'Impressario de Smyrne"</h1>
          <ul>
            <li>
              <span className={styles.propertyName}>Arrière plan : </span>sur{" "}
              <a href="https://levilar.be/wp-content/uploads/2023/03/p6-Smyrne-Tim-Flach.jpg">
                https://levilar.be/wp-content/uploads/2023/03/p6-Smyrne-Tim-Flach.jpg
              </a>{" "}
              copié le 16/09/2023
            </li>
            <li>
              <span className={styles.propertyName}>Icons : </span>sur{" "}
              <a href="https://react-icons.github.io/react-icons/">
                https://react-icons.github.io/react-icons/
              </a>{" "}
              copié le 16/09/2023
            </li>
          </ul>
          <h1>Page "Le garçon du dernier rang"</h1>
          <ul>
            <li>
              <span className={styles.propertyName}>Arrière plan : </span>sur{" "}
              <a href="https://levilar.be/wp-content/uploads/2023/03/IMG_0149.jpg">
                https://levilar.be/wp-content/uploads/2023/03/IMG_0149.jpg
              </a>{" "}
              copié le 27/03/2023
            </li>
            <li>
              <span className={styles.propertyName}>Icons : </span>sur{" "}
              <a href="https://react-icons.github.io/react-icons/">
                https://react-icons.github.io/react-icons/
              </a>{" "}
              copié le 16/09/2023
            </li>
          </ul>
          <h1>Page "Bibliographie"</h1>
          <ul>
            <li>
              <span className={styles.propertyName}>Arrière plan : </span>sur{" "}
              <a href="https://www.slate.fr/sites/default/files/styles/1060x523/public/janko-ferlic-sfl_qonmy00-unsplash_1.jpg">
                https://www.slate.fr/sites/default/files/styles/1060x523/public/janko-ferlic-sfl_qonmy00-unsplash_1.jpg
              </a>{" "}
              copié le 16/09/2023
            </li>
          </ul>
          <br />
          <i>
            NB : Si la source d'un élément n'est pas répertoriée ici, elle est
            disponible sur la page de l'élément même.
          </i>
        </div>
      </section>
      <footer>
        <h1>
          Cours de français 3ème degré - dossier d'expériences culturelles
        </h1>
        <h2 onClick={handleSetPiece("bibliographie")}>Bibliographie</h2>
      </footer>
    </div>
  );
};
