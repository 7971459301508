import { Link } from "react-router-dom"

import styles from './styles/navbar.module.css';
import { useState } from "react";

export const Navbar = () => {
    const [ full, setFull ] = useState(false);

    const handleFull = () => {
        setFull(!full);
    }

    /* <Link to="/cv" className={styles.link}>
    <p>My CV</p>
    </Link>
    */
    
    return (
        <header className={`${styles.navContainer} ${full ? styles.full : ""}`}>
            <nav className={styles.navContent}>
                <Link to="/home" className={styles.link}>
                    <p>Home</p>
                </Link>
                <Link to="/projects" className={styles.link}>
                    <p>My projects</p>
                </Link>
            </nav>
            <div className={styles.burger} onClick={handleFull}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </header>
    )
}