import { Link } from "react-router-dom"
import { Navbar } from "../modules/Navbar"

import styles from './styles/projects.module.css';
import { AiFillGithub } from "react-icons/ai";

export const Projects = () => {
    return (
        <>
            <Navbar />
            <table className={styles.projects}>
                <tr>
                    <th>My Projects</th>
                    <th>See the code</th>
                </tr>
                <tr>
                    <td><Link to="/projects/theatre">CCM - Théatre</Link></td>
                    <td><Link to="/code/theatre">Relative code</Link></td>
                </tr>
                <tr>
                    <td>You want to see more ?</td>
                    <td><AiFillGithub /> <a href="https://github.com/MaxTheRobotCode">Visit my Github</a></td>
                </tr>
            </table>

        </>
    )
}