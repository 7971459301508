import { ReactNode } from "react";
import { Citation } from "./Citation"

import styles from './styles/referencedImage.module.css';

interface ReferencedImageProps {
    src: string;
    reference: ReactNode;
    className?: string;
}

export const ReferencedImage:React.FC<ReferencedImageProps> = ({src, reference, className}) => {
    return (
        <div className={`${styles.referencedImageContainer} ${className}`}>
            <img src={src} alt="" className={styles.image}/>
            <Citation>
                {reference}
            </Citation>
        </div>
    )
}