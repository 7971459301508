import { useState } from 'react';

import styles from '../styles/folder.module.css';

import { AiFillFolder, AiFillFolderAdd, AiOutlineFileAdd } from 'react-icons/ai';

import { FoldersAPI } from '../../scripts/API';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';

export const FoldersManager = () => {
    const [ folders, setFolders ] = useState<any>(null);

    const [ createPath, setCreatePath ] = useState<string>("");
    const [ createName, setCreateName ] = useState<string>("");
    const [ createLink, setCreateLink ] = useState<string>("");

    const [ state, setState ] = useState('list');

    FoldersAPI.getAllFolders().then(response => {
        setFolders(response.data);
    })

    const edit = (element:any) => {
        let isFolder = typeof(folders[element]) === "string";
        setCreatePath(element);
        if(isFolder) {
            setCreateName(folders[element]);
            setState('editFolder')
        } else {
            setCreateName(folders[element].name);
            setCreateLink(folders[element].link);
            setState('editLink')
        }
    }

    const del = (element:any) => {
        let isFolder = typeof(folders[element]) === "string";
        if(isFolder) {
            FoldersAPI.deleteFolder(element).then(response => {
                const status = response.status;
                if(status === 200) {
                    FoldersAPI.getAllFolders().then(response => {
                        setFolders(response.data);
                        setState('list');
                    })
                } else {
                    setState('error');
                }
            })
        } else {
            FoldersAPI.deleteLink(element).then(response => {
                const status = response.status;
                if(status === 200) {
                    FoldersAPI.getAllFolders().then(response => {
                        setFolders(response.data);
                        setState('list');
                    })
                } else {
                    setState('error');
                }
            })
        }
    }

    const handleCreateFolder = () => {
        setState('createFolder');
    }

    const handleCreateLink = () => {
        setState('createLink');
    }

    const handleCreatePathChange = (path:string) => {
        setCreatePath(path);
    }
    const handleCreateNameChange = (name:string) => {
        setCreateName(name);
    }
    const handleCreateLinkChange = (link:string) => {
        setCreateLink(link);
    }

    const sendCreateFolder = () => {
        FoldersAPI.createFolder(createPath, createName).then(response => {
            const status = response.status;
            if(status === 201) {
                FoldersAPI.getAllFolders().then(response => {
                    setFolders(response.data);
                    setState('list');
                })
            } else {
                setState('error');
            }
        })
    }
    const sendCreateLink = () => {
        FoldersAPI.createLink(createPath, createName, createLink).then(response => {
            const status = response.status;
            if(status === 201) {
                FoldersAPI.getAllFolders().then(response => {
                    setFolders(response.data);
                    setState('list');
                })
            } else {
                setState('error');
            }
        })
    }

    const handleGoHome = () => {
        setState('list');
    }

    return (
        <>
            { state === "list" ? (
                <div className={styles.page}>
                    <h1>Admin folders control :</h1>
                    <div className={styles.container}>
                        <div className={styles.header}>
                            <AiFillFolder color='#f8d875'/>
                            <div className={styles.pathContainer} style={{display: `flex`, justifyContent: `space-between`, width: `100%`}}>
                                <p>List of elements</p>
                                <div className={styles.controls}>
                                    <AiFillFolderAdd onClick={() => handleCreateFolder()} className={styles.click} />
                                    <AiOutlineFileAdd onClick={() => handleCreateLink()} className={styles.click} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.body}>
                            {
                                folders ? Object.keys(folders).sort().map((element, k) => (
                                    <>
                                        <div key={"element-" + k} style={{display: `flex`, justifyContent: `space-between`, width: `100%`}}>
                                            <p key={"name-" + k}>{element} --- {typeof(folders[element]) === "string" ? folders[element] : folders[element].name + " (" + folders[element].link + ")"}</p>
                                            <div key={"controls-" + k} className={styles.controls}>
                                                <BsFillPencilFill key={"edit-" + k} onClick={() => edit(element)} className={styles.click}/>
                                                <BsFillTrashFill key={"delete-" + k} onClick={() => del(element)} className={styles.click}/>
                                            </div>
                                        </div>
                                        <hr key={"separator-" + k}/>
                                    </>
                                )) : <h1>Loading...</h1>
                            }
                        </div>
                    </div>
                </div>
            ) : (
            state === "createFolder" ? (
                <div>
                    <h1>Create a folder</h1>
                    <input type="text" onChange={(event) => handleCreatePathChange(event?.target.value)} value={createPath} placeholder='Path'/> <br />
                    <input type="text" onChange={(event) => handleCreateNameChange(event?.target.value)} value={createName} placeholder='Name'/> <br />
                    <button onClick={sendCreateFolder}>Create folder</button>
                    <button onClick={handleGoHome}>Go back</button>
                </div>
            ) : (
            state === "createLink" ? (
                <div>
                    <h1>Create a link</h1>
                    <input type="text" onChange={(event) => handleCreatePathChange(event?.target.value)} value={createPath} placeholder='Path'/> <br />
                    <input type="text" onChange={(event) => handleCreateNameChange(event?.target.value)} value={createName} placeholder='Name'/> <br />
                    <input type="text" onChange={(event) => handleCreateLinkChange(event?.target.value)} value={createLink} placeholder='Link'/> <br />
                    <button onClick={sendCreateLink}>Create link</button>
                    <button onClick={handleGoHome}>Go back</button>
                </div>
            ) : (
            state === "editFolder" ? (
                <div>
                    <h1>Edit a folder</h1>
                    <p>Path : {createPath}</p>
                    <input type="text" onChange={(event) => handleCreateNameChange(event?.target.value)} value={createName} placeholder='Name'/> <br />
                    <button onClick={sendCreateFolder}>Edit folder</button>
                    <button onClick={handleGoHome}>Go back</button>
                </div>
            ) : (
            state === "editLink" ? (
                <div>
                    <h1>Edit a link</h1>
                    <p>Path : {createPath}</p>
                    <input type="text" onChange={(event) => handleCreateLinkChange(event?.target.value)} value={createLink} placeholder='Link'/> <br />
                    <button onClick={sendCreateLink}>Edit link</button>
                    <button onClick={handleGoHome}>Go back</button>
                </div>
            ) : (
                <h1>An error has occured</h1>
            )
            )
            )
            )
            )
            }
        </>
    )
}